import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Animation" description="Guidelines for animating components in Reach UI" mdxType="SEO" />
    <h1 {...{
      "id": "animation"
    }}>{`Animation`}</h1>
    <p>{`Animation is usually boiled down to three phases: enter, update, exit. With Reach UI you're probably just after the "enter" and "exit" phases.`}</p>
    <p>{`You can achieve animation with CSS and/or composing with other React components. On this page we'll look at a few different approaches.`}</p>
    <h2 {...{
      "id": "css-animations"
    }}>{`CSS Animations`}</h2>
    <p>{`You can animate the "enter" phase with just a little CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  border-radius: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .slide-down[data-reach-menu-list],
  .slide-down[data-reach-menu-items] {
    animation: slide-down 0.2s ease;
  }
}
`}</code></pre>
    <p>{`And then add the class to your menu:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      <MenuButton>
        Actions <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList className="slide-down">
        <MenuItem onSelect={() => {}}>Start Video</MenuItem>
        <MenuItem onSelect={() => {}}>Start Screenshare</MenuItem>
        <MenuItem onSelect={() => {}}>Send a Message</MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <p>{`However, you can't animate the "exit" phase with just CSS because React removes the element from the DOM immediately.`}</p>
    <h2 {...{
      "id": "css--recondition-phase"
    }}>{`CSS + reCONDITION Phase`}</h2>
    <p>{`You may want to use CSS for both the enter and exit phases. For this, we can combine some CSS with the `}<inlineCode parentName="p">{`Phased`}</inlineCode>{` component from `}<a parentName="p" {...{
        "href": "https://github.com/theKashey/recondition"
      }}>{`reCONDITION`}</a>{`.`}</p>
    <p>{`First add the CSS to define the animation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.slide-down {
  opacity: 0;
  transform: translateY(-10px);
}

.slide-down.enter {
  opacity: 1;
  transform: translateY(0px);
}

.slide-down.exit {
  opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .slide-down {
    transition-property: transform, opacity;
    transition-duration: 300ms;
  }
}
`}</code></pre>
    <p>{`Then import the Phased component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Phased } from "recondition";
`}</code></pre>
    <p>{`Now we're ready to go:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  // TODO: Update with Phased example
  return (
    <Menu>
      <MenuButton>
        Actions <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList className="slide-down">
        <MenuItem onSelect={() => {}}>Start Video</MenuItem>
        <MenuItem onSelect={() => {}}>Start Screenshare</MenuItem>
        <MenuItem onSelect={() => {}}>Send a Message</MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "react-spring"
    }}>{`React Spring`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);

  const [showDialog, setShowDialog] = React.useState(false);
  const transitions = useTransition(showDialog, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  });
  return (
    <div>
      <button onClick={() => setShowDialog(true)}>Show Dialog</button>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay style={{ opacity: styles.opacity }}>
              <AnimatedDialogContent
                style={{
                  transform: styles.y.to(
                    (value) => \`translate3d(0px, \${value}px, 0px)\`
                  ),
                  border: "4px solid hsla(0, 0%, 0%, 0.5)",
                  borderRadius: 10,
                }}
              >
                <button onClick={() => setShowDialog(false)}>
                  Close Dialog
                </button>
                <p>React Spring makes it too easy!</p>
                <input type="text" />
                <br />
                <input type="text" />
                <button>Ayyyyyy</button>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </div>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      